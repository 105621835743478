import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { useRecoilState } from "recoil"
// import Container from "@material-ui/core/Container"
// import Grid from "@material-ui/core/Grid"
import styled from "styled-components"
import Cookies from "universal-cookie"

import modalAtom from "~atoms/modalAtom"
import FooterMenu from "~components/FooterMenu"
import Hidden from "~components/Hidden"
import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

import ModalContext from "../contexts/ModalContext"
import LogoImage from "../images/logo.png"

const FooterGeneral = styled.div`
  background: #003d6a;
  border-top-right-radius: 25px;

  & {
    position: relative;
    /* padding-bottom: 50px; */
  }
  &:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background: #003d6a;
    top: -50px;
    left: 0;
    z-index: -2;
  }

  &:after {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    border-bottom-left-radius: 25px;
    background: white;
    top: -50px;
    left: 0;
    z-index: -2;
  }
`
const ContainerStyled = styled(Container)`
  padding-top: 43px;
`
const FooterWrapperTop = styled.div`
  display: flex;
  align-items: center;
  min-height: 116px;
  padding-bottom: 30px;

  & img {
    margin-bottom: 0px;
  }
  @media screen and (max-width: 960px) {
    & {
      justify-content: center !important;
    }
  }
`

const LogoWrapper = styled.div`
  padding-top: 7px;
`
const Logo = styled.img`
  width: 218px;
  margin-bottom: 0px;
  image-rendering: crisp-edges;
`

const Text = styled.div`
  color: white;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 250;
  line-height: 16px;
  letter-spacing: 0.4375px;
  @media ${({ theme }) => theme.breakpointDown.xl} {
    br {
      display: none;
    }
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0.4375px;
  }
`

const FooterSVG = styled.svg`
  width: 16px;
  height: 16px;
  color: white;
  margin-right: 8px;
`

const LinkedInSVG = styled.svg`
  width: 16px;
  height: 16px;
  color: white;
  margin-left: 8px;
`

const Heading = styled.li`
  font-size: 18px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0.5625px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 16px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.5px;
  }
`

const ContactLiWrapper = styled.li`
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.4375px;
  svg {
    position: relative;
    top: 4px;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0.4375px;
    & + & {
      margin-top: 5px;
    }
  }
`

const LinkedInWrapper = styled.li`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4375px;
  margin-top: 5px;
  svg {
    position: relative;
    top: 6px;
    width: 20px;
    height: 20px;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 11px;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
`

const CopyrightTitle = styled.li`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4375px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 14px;
    font-weight: 600;
    line-height: 29px !important;
    letter-spacing: 0.4375px;
  }
`
const CopyrightSubTitle = styled.li`
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.4375px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0.375px;
    /* margin-top: px; */
    & + & {
      margin-top: 3px;
    }
  }
`

const FooterUlWrapper = styled.ul`
  list-style: none;
  margin-left: 0;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-left: initial;
  }
  li {
    color: white;
    margin-bottom: 0px;
  }
`

const LogoCol = styled(Col)`
  order: 1;
`
const MenuCol = styled(Col)`
  order: 3;
  &:before {
    content: "Links";
    font-size: 18px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0.5625px;
    color: white;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 2px;

    &:before {
      display: none;
    }
    order: 2;
  }
`
const ContactCol = styled(Col)`
  order: 2;
  margin-top: 13px;
  @media ${({ theme }) => theme.breakpoint.md} {
    order: 3;
    margin-top: 0;
  }
`
const CopywrightCol = styled(Col)`
  order: 4;
`

const Footer = () => {
  // const modalContext = useContext(ModalContext)
  // const [modal, setModal] = useRecoilState(modalAtom)

  // const cookies = new Cookies("registered")

  // useEffect(() => {
  // if (!cookies.get("registered")) {
  //   cookies.set("registered", "true", {
  //     path: "/",
  //   })
  //   setTimeout(() => {
  //     changeModal()
  //     modalContext.modalDispatch("open")
  //   }, 5000)
  // }
  // }, [])

  const TermsAndConditionsQuerry = useStaticQuery(graphql`
    query MyQueryxs {
      wpgraphql {
        page(id: 693, idType: DATABASE_ID) {
          pageBuilder {
            layouts {
              ... on WPGraphQL_Page_Pagebuilder_Layouts_AllNormalContent {
                content
                fieldGroupName
              }
            }
          }
          title
        }
      }
    }
  `)

  const changeModal = () => {
    const modalSettings = {
      normal: true,
      title: TermsAndConditionsQuerry.wpgraphql.page.title,
      content:
        TermsAndConditionsQuerry.wpgraphql.page.pageBuilder.layouts[0].content,
    }
    setModal(modalSettings)
  }

  return (
    <FooterGeneral>
      <FooterWrapperTop>
        <ContainerStyled>
          <Row>
            <LogoCol xs={12} sm={6} md={6} lg={4} xl={4}>
              <LogoWrapper>
                <Logo src={LogoImage} />
              </LogoWrapper>
              <Text>
                A leading retail real estate investment <br />
                management specialist, founded in 2000.
              </Text>
              <Text>
                Pradera Limited is authorised and regulated by the Financial
                Conduct Authority
              </Text>
            </LogoCol>
            <MenuCol xs={12} sm={6} md={6} lg={4} xl={4}>
              <FooterUlWrapper>
                <FooterMenu />
              </FooterUlWrapper>
            </MenuCol>
           <CopywrightCol xs={12} sm={6} md={6} lg={4} xl={4}>
              <FooterUlWrapper className="copywrights">
                <CopyrightTitle>
                  <Link className="no-link" to="/pradera-management-italy">
                    Pradera Management Italy Srl<br />
                    Regulatory Policies
                  </Link>
                </CopyrightTitle>
              </FooterUlWrapper>
            </CopywrightCol>
          </Row>
          <Row>
            <ContactCol xs={12} sm={6} md={6} lg={4} xl={4}>
              <FooterUlWrapper>
                <Heading>Contact</Heading>
                <ContactLiWrapper>
                  <FooterSVG
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </FooterSVG>
                  <a
                    className="no-link"
                    href="tel:+442075395432"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +44 20 7539 5432
                  </a>
                </ContactLiWrapper>

                <ContactLiWrapper>
                  <FooterSVG
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </FooterSVG>
                  <a
                    className="no-link"
                    href="mailto:enquiries@pradera.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    enquiries@pradera.com
                  </a>
                </ContactLiWrapper>
                <LinkedInWrapper>
                  Follow us:
                  <a
                    className="no-link"
                    href="https://www.linkedin.com/company/pradera/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedInSVG
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z" />
                    </LinkedInSVG>
                  </a>
                </LinkedInWrapper>
              </FooterUlWrapper>
            </ContactCol>
            <CopywrightCol xs={12} sm={6} md={6} lg={4} xl={4}>
              <FooterUlWrapper className="copywrights">
                <CopyrightTitle>
                  Powered by:{" "}
                  <a
                    href="https://bwpgroup.com/"
                    className="no-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    BWP Group
                  </a>
                </CopyrightTitle>
                <CopyrightSubTitle>© Copyright 2021 Pradera</CopyrightSubTitle>
                <CopyrightSubTitle>
                  <Link className="no-link" to="/terms-conditions">
                    Terms &amp; Condtions
                  </Link>{" "}
                  |{" "}
                  <Link className="no-link" to="/privacy-policy">
                    Privacy Policy
                  </Link>{" "}
                  |{" "}
                  <Link className="no-link" to="/cookie-policy">
                    Cookie Policy
                  </Link>
                </CopyrightSubTitle>
              </FooterUlWrapper>
            </CopywrightCol>
          </Row>
        </ContainerStyled>
      </FooterWrapperTop>
    </FooterGeneral>
  )
}

export default Footer
